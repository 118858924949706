import React, {useEffect, useMemo, useState} from 'react';
import useScreenSize from "../helpers/hooks/useScreenSize";
import {light, solid, regular} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomSelect from "./UI/CustomSelect";
import {Btn, FlexColumnSection, FlexRowSection, SmallHeader} from "../styles/globalStyles";
import {Collapse, Drawer, Radio, Space} from "antd";
import {t} from "i18next";
import './Filters.scss'
import orderBy from "lodash-es/orderBy";

const FiltersHandler = (props) => {
    const { initConfig, unfilteredData, onFiltered } = props;
    const [config, setConfig] = useState(JSON.parse(JSON.stringify(initConfig)));
    const [openMobileFilters, setOpenMobileFilters] = useState(false);
    const [collapseConfig, setCollapseConfig] = useState(null);
    const {isMobile} = useScreenSize()
    const hasActiveFilters = useMemo(() => config ? Object.values(config).some(filter => filter.isMulti && filter.selected.length > 0) : false, [config]);

    useEffect(() => {
        if(initConfig && config) {
            const newConfig = Object.keys(initConfig).reduce((acc, filterType) => ({...acc, [filterType]: {...initConfig[filterType], selected: config[filterType]?.selected ?? initConfig[filterType]?.selected}}), {})
            setConfig(newConfig)
        }
    }, [initConfig]);

    useEffect(() => {
        if(config) {
            if(Array.isArray(unfilteredData)) { //no one is using this at this moment
                const filteredData = filterArray(unfilteredData)
                onFiltered(filteredData, config)
            } else {
                const filteredData = Object.keys(unfilteredData).reduce((acc, objKey) => {
                    const filtered = filterArray(unfilteredData[objKey])
                    return {...acc, [objKey]: filtered}
                } ,{})
                onFiltered(filteredData, config)
            }
        }
    }, [config]);

    useEffect(() => {
        if(config && isMobile) {
            setCollapseConfig(Object.keys(config).reduce((acc, filterType) => {
                return config[filterType].outside ? acc : [...acc, getCollapseItem(filterType)]
            },[]))
        }
    }, [config]);

    const getCollapseItem = (filterType) => {
        const getChild = () => {
            if(config[filterType].isRadio) {
                return (
                    <Radio.Group onChange={(e) => onSelectionChanged(filterType, e.target.value)} value={config[filterType].selected}>
                        <Space direction="vertical">
                            {config[filterType].options.map(option => <Radio key={`filter-radio-${option.value}`} value={option.value}>{option.label}</Radio>)}
                        </Space>
                    </Radio.Group>
                )
            } else {
                return <CustomSelect onChange={(val) => onSelectionChanged(filterType, val)} style={{width: '100%'}} maxTagCount={'responsive'} {...config[filterType]}/>
            }
        }
        return {
            key: filterType,
            label: <SmallHeader fontSize={'16px'}>{t(`filter-type-${filterType}`)}{config[filterType].isMulti && config[filterType].selected.length > 0 ? `(${config[filterType].selected.length})` : ''}</SmallHeader>,
            children: getChild(),
        }
    }

    const filterArray = (arr) => {
        let filtered = arr.filter(item => {
            return Object.keys(config).reduce((acc, filterType) => {
                if(!initConfig[filterType]?.filterFunc) return acc
                return config[filterType].selected.length > 0 ? (acc && initConfig[filterType]?.filterFunc(item, config[filterType].selected)) : acc
            }, true)
        })

        if(initConfig.sortOption) {
            filtered = initConfig.sortOption.sortFunc(arr, config.sortOption.selected, orderBy)
        }
        return filtered;
    }

    const onSelectionChanged = (filterType, selection) => {
        if(selection) {
            const newFilterType = {...config[filterType], selected: selection}
            setConfig(prev => ({...prev, [filterType]: newFilterType}))
        }
    }

    const resetFilters = () => {
        setConfig(JSON.parse(JSON.stringify(initConfig)))
        setOpenMobileFilters(false)
    }

    return (
        <>{
            isMobile ?
                <>
                    <FlexRowSection gap={'10px'} flex={'unset'} justify={'space-between'}>
                        <FlexRowSection gap={'10px'}>{config && Object.keys(config).map(filterType => config[filterType].outside ? <CustomSelect key={`filter-select-${filterType}`} onChange={(val) => onSelectionChanged(filterType, val)} style={{flex: 1, maxWidth: '220px'}} maxTagCount={'responsive'} {...config[filterType]}/> : null)}</FlexRowSection>
                        {collapseConfig?.length > 0 && <div onClick={() => setOpenMobileFilters(true)}><FontAwesomeIcon icon={hasActiveFilters ? solid('filter') : light('filter')} color={Colors.siteBlack} size={'lg'}/></div>}
                    </FlexRowSection>
                    <Drawer
                        placement={'bottom'}
                        closable={false}
                        onClose={() => setOpenMobileFilters(false)}
                        open={openMobileFilters}
                        style={{borderTopLeftRadius: 20, borderTopRightRadius: 20}}
                        title={<SmallHeader>{t('filters')}</SmallHeader>}
                        extra={hasActiveFilters ? <span onClick={resetFilters} style={{fontSize: '14px'}}>{t('clear')}</span> : null}
                        className={'mobile-filters-drawer'}
                    >
                        <FlexColumnSection gap={'20px'}>
                            <Collapse accordion
                                      items={collapseConfig}
                                      bordered={false}
                                      style={{background: Colors.white}}
                                      className={'mobile-filters-collapse'}
                                      expandIconPosition={'end'}
                                      expandIcon={({ isActive }) => <FontAwesomeIcon icon={light('chevron-down')} color={Colors.siteBlack} flip={isActive ? 'vertical' : 'horizontal'} size={'xs'}/>}
                            />
                            <Btn type={'primary'} onClick={() => setOpenMobileFilters(false)} width={'100%'}>{t('view-sessions')}</Btn>
                        </FlexColumnSection>
                    </Drawer>
                </>
                :
                <FlexRowSection gap={'10px'}>
                    {config && Object.keys(config).map(filterType => <CustomSelect key={`filter-select-${filterType}`} onChange={(val) => onSelectionChanged(filterType, val)} style={{flex: 1, maxWidth: '150px'}} maxTagCount={'responsive'} {...config[filterType]}/>)}
                </FlexRowSection>
            }
        </>
    );
};

export default FiltersHandler;