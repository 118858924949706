import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import {t} from "i18next";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {FlexRowSection} from "../styles/globalStyles";

const SessionBookingInfo = (props) => {
    const { displayProps, restStyle } = props;
    const loggedIn = useSelector(state => state.auth.loggedIn)

    return (
        <>
            {loggedIn &&
            <RowSection gap={'8px'} flex={'unset'} restStyle={restStyle}>
                {(displayProps.showNumRegistered || displayProps.showNumOpenSpots) && <FontAwesomeIcon icon={solid('user')} color={Colors.siteBlack} size={'xs'}/>}
                {displayProps.showNumRegistered && displayProps.showNumOpenSpots && <span>{displayProps.registered}/{displayProps.maxUsers}</span>}
                {displayProps.showNumRegistered && !displayProps.showNumOpenSpots && <span>{t('num-booked', {num: displayProps.registered})}</span>}
                {!displayProps.showNumRegistered && displayProps.showNumOpenSpots && <span>{displayProps.openSpots === 0 ? t('full') : t('open-spots', {num: displayProps.openSpots})}</span>}
            </RowSection>}
        </>
    );
};

export default SessionBookingInfo;

const RowSection = styled(FlexRowSection)`
    ${({restStyle}) => restStyle ?? ''}
`;