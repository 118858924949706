import React from 'react';
import {Avatar} from "antd";
import {getUserInitials} from "../helpers/functions";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";

const UserAvatar = (props) => {
    const { user, nameType, disabled } = props;

    return (
        <span style={{display: 'flex', alignItems: 'center', gap: '10px', width: 'max-content'}}>
            <Avatar size={30} src={user?.cloudinary_image ?? user?.user_image} style={{backgroundColor: Colors.weekDayDark, fontSize: '14px', opacity: disabled ? '50%' : '100%'}}>{getUserInitials(user)}</Avatar>
            <span>{nameType === 'full' ? user.full_name : nameType === 'firstName' ? user.first_name : ''}</span>
        </span>
    );
};

export default UserAvatar;