import React, {useEffect, useMemo, useState} from 'react';
import {FlexColumnSection, FlexDiv, FlexRowSection} from "../../../styles/globalStyles";
import {Calendar, Spin} from "antd";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {t} from "i18next";
import {useDispatch, useSelector} from "react-redux";
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import './AvailabilitySlots.scss'
import {apiAction} from "@arboxappv4/shared/src/helpers/HTTP";
import dayjs from "dayjs";
import {DB_DATE_FORMAT} from "@arboxappv4/shared/src/constants/constants";
import orderBy from "lodash-es/orderBy";
import EmptyCalendar from "../../../svg/EmptyCalendar";
import CustomSelect from "../../../components/UI/CustomSelect";
import SlotsDisplay from "./SlotsDisplay";
import {openModal, SESSION_MEMBERSHIP_MODAL} from "@arboxappv4/shared/src/redux/modalManagerSlice";
import {addFiltersToQueryParams, checkIfDateAfterToday, checkIfDateBeforeToday} from "../../../helpers/functions";
import {setTargetName} from "@arboxappv4/shared/src/redux/stepperSlice";
import iconGenerator from "../../../helpers/IconGenerator";
import {iconPrefix} from "@arboxappv4/shared/src/helpers/constants";
import locale from 'antd/es/date-picker/locale/he_IL';
import 'dayjs/locale/he'


const AvailabilitySlots = (props) => {
    const { values, setFieldValue, handleSubmit, isCoachAppointment } = props;
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const box = useSelector(state => state.site.box)
    const loggedIn = useSelector(state => state.auth.loggedIn)
    const error = useSelector(state => state.stepper.error)
    const language = useSelector(state => state.site.language)

    const [loading, setLoading] = useState(false);
    const [slotsLoading, setSlotsLoading] = useState(true);
    const [slots, setSlots] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedCoachOrSpace, setSelectedCoachOrSpace] = useState('all');
    const [selectedProps, setSelectedProps] = useState({id: null, payForSlot: null, schedule: null});
    const [submitted, setSubmitted] = useState(false);
    const {isMobile} = useScreenSize()
    const dispatch = useDispatch()

    const durationObj = useMemo(() => (values.service ? {hours: Math.floor(values.service.length / 60), minutes: values.service.length % 60} : null), [values.service]);
    const currencySymbol = useMemo(() => globalLocation?.currency_symbol, [globalLocation]);
    const emptyState = useMemo(() => (slots && slots.length === 0), [slots]);
    const selectedLocation = useMemo(() => values.locations_box_fk, [values.locations_box_fk]);
    const location = useMemo(() => box?.locations_box.find(l => l.id === selectedLocation), [selectedLocation, box]);

    const coaches = useMemo(() => {
        if(slots && isCoachAppointment) {
            const coachOptions = Object.values(slots.reduce((acc, slot) => ({...acc, [slot.coach_ub_id]: {...slot.coach, value: slot.coach_ub_id, label: slot.coach.full_name}}), {}))
            return [{value: 'all', label: t('all-coaches')}, ...coachOptions]
        }
    }, [slots]);

    const spaces = useMemo(() => {
        if(slots && !isCoachAppointment) {
            const spaceOptions = Object.values(slots.reduce((acc, slot) => ({...acc, [slot.spaces_id]: {...slot.space, value: slot.spaces_id, label: slot.space.name}}), {}))
            return [{value: 'all', label: t('all-spaces')}, ...spaceOptions]
        }
    }, [slots]);

    const filteredSlots = useMemo(() => {
        if(slots) {
            if(selectedCoachOrSpace === 'all') return slots
            else if(isCoachAppointment) return slots.filter(slot => slot.coach_ub_id === selectedCoachOrSpace)
            else return slots.filter(slot => slot.spaces_id === selectedCoachOrSpace)
        }
    }, [selectedCoachOrSpace, slots]);

    const isSmallSlot = useMemo(() => {
        // 2 because we always have "all" option
        if(isCoachAppointment && coaches && coaches.length === 2) return true
        else return !isCoachAppointment && spaces && spaces.length === 2;
    }, [spaces, coaches]);

    useEffect(() => {
        if(selectedLocation && values.service) {
            dispatch(setTargetName(values.service.name))
            initClosestDate()
        }
        return () => dispatch(setTargetName(null))
    }, [selectedLocation, values.service]);

    useEffect(() => {
        if(selectedDate && selectedLocation) {
            addFiltersToQueryParams({location: selectedLocation})
            fetchSlots()
        }
    }, [selectedDate, selectedLocation]);

    useEffect(() => {
        //this is to be 100% sure we don't submit before the values are properly submitted (because setFieldValue is async)
        if(values.id === selectedProps.id &&
            values.payForSlot === selectedProps.payForSlot &&
            values.schedule &&
            values.schedule?.id === selectedProps.schedule?.id &&
            !submitted
        ) {
            handleSubmit()
            setSubmitted(true)
        }
    }, [values.id, values.payForSlot, selectedProps, submitted]);

    useEffect(() => {
        if(error) {
            console.log('submitting error',error)
            setSelectedProps({id: null, payForSlot: null, schedule: null})
            setSubmitted(false)
        }
    }, [error]);

    const initClosestDate = async () => {
        try {
            setLoading(true);
            const params = {
                locations_box_id: selectedLocation,
                box_categories_id: values.service.id,
                isCoachAvailability: isCoachAppointment
            }
            if (!selectedDate) {
                const date = await apiAction('availabilities/getClosestAvailability', 'post', params);
                setSelectedDate(dayjs(date, DB_DATE_FORMAT))
            }
            setLoading(false)
        } catch (error) {
            console.log('error initClosestDate', error)
            setLoading(false)
        }
    }

    const fetchSlots = async () => {
        try {
            setSlotsLoading(true);
            const params = {
                date: selectedDate.format(DB_DATE_FORMAT),
                locations_box_id: selectedLocation,
                box_categories_id: values.service.id,
                isCoachAvailability: isCoachAppointment
            }
            const response = await apiAction('availabilities/byDate', 'post', params);
            const sorted = orderBy(response, ['time', slot => isCoachAppointment ? slot.coach.full_name : slot.space.name]);
            setSlots(sorted)
            setSlotsLoading(false)
        } catch (error) {
            console.log('error fetchSlots', error)
            setSlotsLoading(false)
        }
    }

    const openUserMembershipPopup = (slot) => {
        dispatch(openModal({modalName: SESSION_MEMBERSHIP_MODAL, props: {
            session: slot,
            title: slot.box_categories.name,
            ignoreDisabledPages: true,
            onMembershipSelect: ({payForSlot, id}) => {
                setSelectedProps({id, payForSlot, schedule: slot})
                setFieldValue('payForSlot', payForSlot)
                setFieldValue('id', id)
                setFieldValue('schedule', slot)
                if(!loggedIn) setFieldValue('locations_box_fk', slot.locations_box_fk)
            }
        }}))
    }

    const getLocationAndSpaceOrCoach = () => {
        if(slotsLoading || loading) return null
        if(isSmallSlot) {
            return (
                <FlexRowSection gap={'5px'} flex={'unset'}>
                    <FontAwesomeIcon icon={iconGenerator({name: isCoachAppointment ? 'user' : 'location-dot', type: iconPrefix.SOLID})} color={Colors.siteBlack} size={'md'}/>
                    <span>{isCoachAppointment ? filteredSlots[0].coach.full_name : filteredSlots[0].space.name}{box?.locations_box.length > 1 ? `, ${location?.location}` : ''}</span>
                </FlexRowSection>
            )
        } else {
            if(box?.locations_box.length === 1) {
                return null
            } else {
                return (
                    <FlexRowSection gap={'5px'} flex={'unset'}>
                        <FontAwesomeIcon icon={solid('map-location-dot')} color={Colors.siteBlack} size={'md'}/>
                        <span>{location?.location}</span>
                    </FlexRowSection>
                )
            }
        }
    }

    return (
        <>
            {loading ? <FlexColumnSection justify={'center'}><Spin/></FlexColumnSection>
            :
            <FlexDiv dir={isMobile ? 'column' : 'row'} gap={isMobile ? '15px' : '40px'} flex={1} padding={isMobile ? '0 16px' : 0}  overflow={isMobile ? 'hidden auto' : 'hidden'}>
                <div style={{width: isMobile ? '100%' : '350px'}}>
                    <FlexColumnSection gap={isMobile ? '10px' : 0}>
                        {getLocationAndSpaceOrCoach()}
                        <FlexRowSection gap={'30px'} flex={'unset'} height={isMobile ? 'auto' : '45px'}>
                            {values.service?.price >= 0 &&
                            <FlexRowSection gap={'5px'} flex={'unset'}>
                                <FontAwesomeIcon icon={solid('circle-dollar')} color={Colors.siteBlack} size={'sm'}/>
                                <span>{t('price')}: {values.service?.price > 0 ? `${currencySymbol}${values.service?.price}` : t('free')}</span>
                            </FlexRowSection>}
                            <FlexRowSection gap={'5px'} flex={'unset'}>
                                <FontAwesomeIcon icon={solid('clock')} color={Colors.siteBlack} size={'sm'}/>
                                {durationObj &&
                                <span>{t('duration')}:
                                    <span> {durationObj.hours > 0 && `${durationObj.hours} ${t('hours')} `}</span>
                                    <span> {durationObj.minutes > 0 && `${durationObj.minutes} ${t('minutes')}`}</span>
                                </span>}
                            </FlexRowSection>
                        </FlexRowSection>
                        {selectedDate &&
                        <Calendar fullscreen={false}
                                  className={'availability-slot-calendar'}
                                  value={selectedDate}
                                  onSelect={setSelectedDate}
                                  disabledDate={checkIfDateBeforeToday}
                                  locale={language?.code === 'he' ? locale : null}
                        />}
                    </FlexColumnSection>
                </div>
                <FlexColumnSection padding={isMobile ? 0 : '45px 0 0'} gap={'15px'} overflow={isMobile ? 'hidden unset' : 'hidden'}>
                    <FlexRowSection flex={'unset'} gap={'10px'}>
                        {coaches && coaches.length > 2 && <CustomSelect onChange={(val) => val ? setSelectedCoachOrSpace(val) : null} options={coaches} defaultValue={selectedCoachOrSpace} style={{flex: isMobile ? 1 : 'unset'}}/>}
                        {spaces && spaces.length > 2 && <CustomSelect onChange={(val) => val ? setSelectedCoachOrSpace(val) : null} options={spaces} defaultValue={selectedCoachOrSpace} style={{flex: isMobile ? 1 : 'unset'}}/>}
                    </FlexRowSection>
                    {slotsLoading && <FlexColumnSection justify={'center'}><Spin/></FlexColumnSection>}
                    {emptyState && !slotsLoading &&
                    <div style={{width: '100%'}}>
                        <FlexColumnSection gap={'20px'} align={'center'} justify={'center'}>
                            <EmptyCalendar width={150}/>
                            <div>{t('no-slots-label')}</div>
                        </FlexColumnSection>
                    </div>}
                    <FlexColumnSection overflow={'hidden auto'}>
                        {!slotsLoading && filteredSlots && <SlotsDisplay slots={filteredSlots} isCoachAppointment={isCoachAppointment} isSmall={isSmallSlot} onSlotSelect={openUserMembershipPopup}/>}
                    </FlexColumnSection>
                </FlexColumnSection>
            </FlexDiv>}
        </>
    );
};

export default AvailabilitySlots;