import React, {useMemo} from 'react';
import {FlexColumnSection, FlexRowSection} from "../styles/globalStyles";
import {DB_DATE_FORMAT} from "@arboxappv4/shared/src/constants/constants";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import UserAvatar from "./UserAvatar";
import {sessionTypes} from "@arboxappv4/shared/src/helpers/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import useScreenSize from "../helpers/hooks/useScreenSize";
import {getDisabledPagesProps} from "@arboxappv4/shared/src/helpers/DisablePages";
import {useSelector} from "react-redux";
import {t} from "i18next";
import dayjs from "dayjs";

const SessionFullInfo = (props) => {
    const { session, ignoreDisabledPages } = props;
    const language = useSelector(state => state.site.language)
    const box = useSelector(state => state.site.box)
    const loggedIn = useSelector(state => state.auth.loggedIn)

    const {isMobile} = useScreenSize();

    const displayProps = useMemo(() => session ? getDisabledPagesProps(session) : {}, [session])
    return (
        <FlexColumnSection gap={'8px'}>
            <FlexRowSection gap={'10px'}>
                <span>{dayjs(session.date, DB_DATE_FORMAT).locale(language?.code ?? 'en').format(`${language?.code === 'he' ? 'D MMMM' : 'MMMM D'}, YYYY`)}</span>
                <FontAwesomeIcon icon={solid('circle-small')} color={Colors.siteBlack} className={'dot-icon'}/>
                <span>{dayjs(session.time, 'HH:mm').format(session.locations_box.time_format)} - {dayjs(session.end_time, 'HH:mm').format(session.locations_box.time_format)}</span>
            </FlexRowSection>
            {(displayProps.showCoach || ignoreDisabledPages) &&
            <FlexRowSection gap={'10px'} mobile={isMobile}>
                {session.coach && <UserAvatar user={session.coach} nameType={'full'}/>}
                {session.second_coach && <FontAwesomeIcon icon={solid('circle-small')} color={Colors.siteBlack} className={'dot-icon'}/>}
                {session.second_coach && <UserAvatar user={session.second_coach} nameType={'full'}/>}
            </FlexRowSection>}
            {session.live_link && <span>{t('online-session')}</span>}
            <FlexRowSection gap={'10px'} mobile={isMobile}>
                {box.locations_box.length > 1 && <span>{session.locations_box.location}</span>}
                {box.locations_box.length > 1 && session.spaces && (displayProps.showSpaceName || ignoreDisabledPages) && <FontAwesomeIcon icon={solid('circle-small')} color={Colors.siteBlack} className={'dot-icon'}/>}
                {session.spaces && (displayProps.showSpaceName || ignoreDisabledPages) && <span>{t('space')}: {session.spaces.name}</span>}
            </FlexRowSection>
            {loggedIn && session.box_categories.category_type?.name === sessionTypes.CLASS &&
            <FlexRowSection gap={'10px'} mobile={isMobile}>
                {(displayProps.showNumRegistered || displayProps.showNumOpenSpots) && <FontAwesomeIcon icon={solid('user')} color={Colors.siteBlack} size={'xs'}/>}
                {displayProps.showNumRegistered && displayProps.showNumOpenSpots && <span>{displayProps.registered}/{displayProps.maxUsers}</span>}
                {displayProps.showNumRegistered && !displayProps.showNumOpenSpots && <span>{t('num-booked', {num: displayProps.registered})}</span>}
                {!displayProps.showNumRegistered && displayProps.showNumOpenSpots && <span>{displayProps.openSpots === 0 ? t('full') : t('open-spots', {num: displayProps.openSpots})}</span>}
                {loggedIn && displayProps.showWaitList && displayProps.inWaitList > 0 &&
                <>
                    <span>({displayProps.inWaitList})</span>
                    {displayProps.positionInWaitList &&
                    <>
                        <FontAwesomeIcon icon={solid('circle-small')} color={Colors.siteBlack} className={'dot-icon'}/>
                        <span>{t('place-in-waitlist', {num: displayProps.positionInWaitList})}</span>
                    </>
                    }
                </>
                }
            </FlexRowSection>}
        </FlexColumnSection>
    );
};

export default SessionFullInfo;