import React, {useMemo} from 'react';
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import {useSelector} from "react-redux";
import {FlexColumnSection, FlexRowSection} from "../../../styles/globalStyles";
import {getMembershipPriceText, sortCourseEventDays} from "../../../helpers/functions";
import dayjs from "dayjs";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {t} from "i18next";
import UserAvatar from "../../../components/UserAvatar";

const CourseInfo = (props) => {
    const { course } = props;
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const {isMobile} = useScreenSize()

    const currencySymbol = useMemo(() => globalLocation?.currency_symbol, [globalLocation]);
    let days = useMemo(() => course ? sortCourseEventDays(course.series_events) : [], [course])

    if(!course) return <></>

    return (
        <FlexColumnSection gap={isMobile ? '10px' : '15px'} flex={'unset'}>
            <span>{getMembershipPriceText(course.membership_types[0], currencySymbol)}</span>
            <FlexRowSection gap={'10px'}>
                <div>{dayjs(course.start_date, 'YYYY-MM-DD').format(globalLocation.date_format)} - {dayjs(course.end_date, 'YYYY-MM-DD').format(globalLocation.date_format)}</div>
                <FontAwesomeIcon icon={solid('circle-small')} color={Colors.siteBlack} className={'dot-icon'}/>
                <span>{days.map(day => t(`day-letter-${day.day_of_week}`)).join(', ')}</span>
            </FlexRowSection>
            {course.coach && <UserAvatar user={course.coach} nameType={'full'}/>}
            {course.second_coach && <UserAvatar user={course.second_coach} nameType={'full'}/>}
            <span>{course.locations_box.location}</span>
        </FlexColumnSection>
    );
};

export default CourseInfo;