import React from 'react';
import useScreenSize from "../../helpers/hooks/useScreenSize";
import {Btn, FlexColumnSection, Header, SmallText} from "../../styles/globalStyles";
import DownloadApp from "../../components/UI/DownloadApp";
import SuccessSchedule from "../../svg/SuccessSchedule";
import SuccessRegister from "../../svg/SuccessRegister";
import SuccessPurchase from "../../svg/SuccessPurchase";
import SuccessWaiver from "../../svg/SuccessWaiver";
import SuccessShop from "../../svg/SuccessShop";
import styled from "styled-components";

const Success = (props) => {
    const { header, subheader, onCTAClick, ctaText, svgType} = props;
    const {isMobile} = useScreenSize()

    const getSVG = () => {
        switch(svgType) {
            case 'schedule':
                return <SuccessSchedule width={150}/>
            case 'register':
                return <SuccessRegister width={150}/>
            case 'membership':
                return <SuccessPurchase width={150}/>
            case 'waiver':
                return <SuccessWaiver width={150}/>
            case 'shop':
                return <SuccessShop width={150}/>
            default:
                return <SuccessShop width={150}/>
        }
    }

    return (
        <FlexColumnSection gap={'15px'} align={'center'} padding={'40px 16px'}>
            {!isMobile && getSVG()}
            <FlexColumnSection gap={'10px'} align={isMobile ? 'start' : 'center'} flex={'none'}>
                <Header>{header}</Header>
                <SmallTextCenter>{subheader}</SmallTextCenter>
                {onCTAClick && <Btn type={'primary'} onClick={onCTAClick} width={isMobile ? '100%' : null}>{ctaText}</Btn>}
            </FlexColumnSection>
            <div style={{marginTop: '20px', width: isMobile ? '100%' : 'auto'}}>
                <DownloadApp/>
            </div>
        </FlexColumnSection>
    );
};

export default Success;

const SmallTextCenter = styled(SmallText)`
    text-align: center;
`