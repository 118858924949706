import React, {lazy} from 'react';
import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import {flowOptions} from "./src/flowManager/FlowOptions";
import FlowManager from "./src/flowManager/FlowManager";
import WaiverFlow from "./src/screens/waiver/WaiverFlow";
import {APPOINTMENT_TYPE_SPACE, APPOINTMENT_TYPE_STAFF} from "@arboxappv4/shared/src/constants/constants";

const Home = lazy(() => import(/* webpackChunkName: "Home" */'./src/screens/home/Content'));
const HomeContainer = lazy(() => import(/* webpackChunkName: "HomeContainer" */'./src/screens/home/HomeContainer'));
const Register = lazy(() => import(/* webpackChunkName: "RegisterFlow" */'./src/screens/register/RegisterFlow'));
const MembershipPurchase = lazy(() => import(/* webpackChunkName: "MembershipPurchaseFlow" */'./src/screens/membershipPurchase/MembershipPurchaseFlow'));
const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */'./src/screens/404/NotFound'));
const GroupSessionsFlow = lazy(() => import(/* webpackChunkName: "GroupSessionsFlow" */'./src/screens/groupSessions/GroupSessionsFlow'));
const AppointmentFlow = lazy(() => import(/* webpackChunkName: "AppointmentFlow" */'./src/screens/appointment/AppointmentFlow'));
const CourseFlow = lazy(() => import(/* webpackChunkName: "CourseFlow" */'./src/screens/course/CourseFlow'));
const ShopFlow = lazy(() => import(/* webpackChunkName: "CourseFlow" */'./src/screens/shop/ShopFlow'));

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<HomeContainer />}>
            <Route path="/" element={<Home />} exact />
            <Route path="staff-appointment" element={<AppointmentFlow type={APPOINTMENT_TYPE_STAFF}/>} />
            <Route path="space-appointment" element={<AppointmentFlow type={APPOINTMENT_TYPE_SPACE}/>} />
            <Route path="group" element={<GroupSessionsFlow/>}>
                <Route path=":id" />
            </Route>
            <Route path="shop" element={<ShopFlow/>}>
                <Route path=":id" />
            </Route>
            <Route path="membership" element={<MembershipPurchase/>}>
                <Route path=":id" />
            </Route>
            <Route path="course" element={<CourseFlow/>}>
                <Route path=":id" />
            </Route>
            <Route path="register" element={<Register />} />
            <Route path='*' element={<NotFound />}/>
            <Route path="form" element={<WaiverFlow/>}/>
        </Route>
    )
);

export default router