import React, {useEffect, useMemo, useState} from 'react';
import {apiAction} from "@arboxappv4/shared/src/helpers/HTTP";
import {useDispatch, useSelector} from "react-redux";
import {Spin} from "antd";
import {FlexColumnSection, FlexDiv} from "../../../styles/globalStyles";
import CustomCollapse from "../../../components/UI/CustomCollapse";
import {t} from "i18next";
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import styled from "styled-components";
import {sortAlphabetically} from "@arboxappv4/shared/src/helpers/functions";
import {updateFlags} from "@arboxappv4/shared/src/redux/stepperSlice";
import CustomSelect from "../../../components/UI/CustomSelect";
import {addFiltersToQueryParams, onLinkClicked} from "../../../helpers/functions";

const ServiceTypeSelection = (props) => {
    const { values, setFieldValue, handleSubmit, isCoachAppointment } = props;
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const box = useSelector(state => state.site.box)
    const flags = useSelector(state => state.stepper.flags)
    const [services, setServices] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const {isMobile} = useScreenSize()
    const dispatch = useDispatch()

    const currencySymbol = useMemo(() => globalLocation?.currency_symbol, [globalLocation]);
    const locations = useMemo(() =>  box?.locations_box.reduce((acc, item) => {
        if((isCoachAppointment && item.hasAvailability) || (!isCoachAppointment && item.hasSpaceAvailability)) {
            return [...acc, {...item, value: item.id, label: item.location}]
        }
        return acc
    }, []), [box, isCoachAppointment]);

    useEffect(() => {
        console.log('globalLocation', globalLocation, values)
        if(globalLocation && locations.length) {
            if(values.locations_box_fk) {
                setSelectedLocation(values.locations_box_fk)
            } else if((isCoachAppointment && globalLocation.hasAvailability) || (!isCoachAppointment && globalLocation.hasSpaceAvailability)) {
                setSelectedLocation(globalLocation?.id)
            } else {
                setSelectedLocation(locations[0].id)
            }
        }
    }, [globalLocation, locations]);

    useEffect(() => {
        if(box && selectedLocation && !loading) {
            setServices(null)
            getServiceTypes()
            addFiltersToQueryParams({location: selectedLocation})
        }
    }, [box, selectedLocation, isCoachAppointment]);

    useEffect(() => {
        if(values.service && flags.serviceReady && values.locations_box_fk) {
            dispatch(updateFlags({serviceReady: false}))
            handleSubmit()
        }
    }, [values.service, flags.serviceReady, values.locations_box_fk]);

    const getServiceTypes = async () => {
        setLoading(true)
        const response = await apiAction(`appointment/types/${box.id}/${selectedLocation}/${isCoachAppointment}`, 'get');
        setServices(sortAlphabetically(response, 'name'))
        setLoading(false)
    }

    const serviceSelected = (service) => {
        setFieldValue('service', service)
        setFieldValue('locations_box_fk', selectedLocation)
        dispatch(updateFlags({serviceReady: true, locations_box_fk: selectedLocation}))
    }

    const getServiceInfo = (service) => {
        const hours = Math.floor(service.length / 60)
        const minutes = service.length % 60
        return (
            <FlexDivWidth dir={isMobile ? 'column' : 'row'} gap={isMobile ? '5px' : '60px'} mobile={isMobile}>
                {service.price > 0 ?
                    <div style={{width: '50px'}}>{currencySymbol}{service.price.toLocaleString()}</div>
                    :
                    <div style={{width: '50px'}}>{t(service.price === null ? 'no-price' : 'free')}</div>
                }
                <span>
                    <span>{hours > 0 && `${hours} ${t('hours')} `}</span>
                    <span>{minutes > 0 && `${minutes} ${t('minutes')}`}</span>
                </span>
            </FlexDivWidth>
        )
    }

    return (
        <FlexColumnSection gap={'10px'} overflow={'hidden'} padding={isMobile ? '0 16px' : 0}>
            {selectedLocation && locations.length > 1 && <CustomSelect onChange={(val) => val ? setSelectedLocation(val) : null} options={locations} defaultValue={selectedLocation} style={{width: '200px'}}/>}
            {loading && <FlexColumnSection justify={'center'}><Spin/></FlexColumnSection>}
            {services &&
                <FlexColumnSection gap={'10px'} overflow={'auto'}>
                    {services.map(service => (
                        <CustomCollapse headerText={service.name}
                                        disableExpand={'icon'}
                                        ctaText={t('book')}
                                        headerInfo={getServiceInfo(service)}
                                        onBtnClick={() => serviceSelected(service)}
                                        key={`appointment-service-${service.id}`}
                                        restStyles={'justify-content: space-between;'}
                        />
                    ))}
                    {services.length === 0 && <div style={{width: '100%'}}>{t('appointment-no-services-in-location')}</div>}
                </FlexColumnSection>
            }
        </FlexColumnSection>
    );
};

export default ServiceTypeSelection;

const FlexDivWidth = styled(FlexDiv)`
    width: ${({mobile}) => mobile ? '100%' : '250px'};
`;