import React from 'react';
import {FlexColumnSection, SmallText} from "../../../styles/globalStyles";
import {t} from "i18next";

const MembershipDescription = (props) => {
    const { membership } = props;

    if(!membership) return null

    return (
        <FlexColumnSection gap={'20px'}>
            {!membership.description && membership.limitations?.length === 0 && <SmallText>{t('membership-collapse-empty')}</SmallText>}
            <span>{membership.description}</span>
            <FlexColumnSection gap={'15px'} overflow={'auto'}>
                {membership.limitations?.length > 0 &&
                <FlexColumnSection gap={'20px'} flex={'none'}>
                    {membership.limitations.map(limitation => (
                        <FlexColumnSection key={`direct-membership-limitation-${limitation.header}`} flex={'none'}>
                            <SmallText weight={'bold'}>{limitation.header}</SmallText>
                            {limitation.values.map((value, i) => <SmallText key={`direct-membership-limitation-${limitation.header}-${i}`}>{value}</SmallText>)}
                        </FlexColumnSection>
                    ))}
                </FlexColumnSection>
                }
            </FlexColumnSection>
        </FlexColumnSection>
    );
};

export default MembershipDescription;