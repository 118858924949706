import React, {useEffect, useMemo, useState} from 'react';
import {Select} from "antd";
import {t} from "i18next";
import {light} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Colors} from '@arboxappv4/shared/src/styles/Colors'
import useScreenSize from "../../helpers/hooks/useScreenSize";

const CustomSelect = (props) => {
    const { isMulti, options, onChange, defaultValue, valueProp, labelProp, enableSingleSelection, ...rest } = props;
    const [selection, setSelection] = useState(null);
    const [initSelection, setInitSelection] = useState(defaultValue ?? null);
    const {isMobile} = useScreenSize()

    const selectOptions = useMemo(() => options.reduce((acc, option) => [...acc, {...option, label: option[labelProp ?? 'label'], value: option[valueProp ?? 'value']}],[]), [options]);

    // both useeffects are a workaround for "default value": store the default value in initSelection --> set selection to same value --> use initSelection as (default) value inside select component --> reset initSelection on selection change
    useEffect(() => {
        if(defaultValue) {
            setSelection(defaultValue)
        }
    }, [defaultValue]);

    useEffect(() => {
        onChange(selection)
        setInitSelection(null)
    }, [selection]);

    if(options.length <= 1 && !enableSingleSelection) return null

    return (
        <>
            {isMulti ?
                <Select
                    showSearch={!isMobile}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    onChange={setSelection}
                    mode="multiple"
                    suffixIcon={<FontAwesomeIcon icon={light('chevron-down')}/>}
                    options={selectOptions}
                    {...rest}
                />
                :
                <Select showSearch={!isMobile}
                        optionFilterProp="children"
                        placeholder={<div style={{color: Colors.siteBlack, margin: '0 5px'}}>{t('select')}</div>}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        filterSort={(optionA, optionB) => {
                            if (optionA.value === 'all') return -1;
                            if (optionB.value === 'all') return 1;
                            return (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}}
                        {...rest}
                        suffixIcon={<FontAwesomeIcon icon={rest?.suffixIcon ?? light('chevron-down')}/>}
                        onChange={setSelection}
                        value={initSelection ?? selection}
                        options={selectOptions}
                />
            }
        </>
    );
};

export default CustomSelect;
